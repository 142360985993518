import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

const NotFound = () =>
  import(
    /* webpackChunkName: "NotFound" */ "@/pages/GeneralViews/NotFoundPage"
  );
const Forbidden = () =>
  import(
    /* webpackChunkName: "NotFound" */ "@/pages/GeneralViews/ForbiddenPage"
  );
const Login = () =>
  import(/* webpackChunkName: "Login" */ "@/pages/Auth/Login.vue");
const Logout = () =>
  import(/* webpackChunkName: "Logout" */ "@/pages/Auth/Logout.vue");
const AuthLayout = () =>
  import(
    /* webpackChunkName: "GeneralScreenDashboard" */ "@/pages/Layouts/AuthLayout.vue"
  );
const GeneralScreenDashboard = () =>
  import(
    /* webpackChunkName: "GeneralScreenDashboard" */ "@/pages/Layouts/GeneralScreenDashboard.vue"
  );
const EmptyContent = () =>
  import(
    /* webpackChunkName: "GeneralScreenDashboard" */ "@/pages/Layouts/EmptyContent.vue"
  );
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Dashboard/index.vue");

const UserList = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Users/List.vue");
const UserForm = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Users/Form.vue");
const UserDetail = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Users/Detail.vue");

const Koordinator = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Koordinator/index.vue");

const StaffCalonList = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/StaffCalon/List.vue");
const StaffCalonForm = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/StaffCalon/Form.vue");
const StaffCalonDetail = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/StaffCalon/Detail.vue");

const CalegList = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Caleg/List.vue");
const CalegForm = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Caleg/Form.vue");
const CalegDetail = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Caleg/Detail.vue");

const PerolehanSuara = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/index.vue"
  );
const PerolehanSuaraListPartai = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/partyList.vue"
  );
const PerolehanSuaraPartai = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/selectedParty.vue"
  );
// const PerolehanSuaraPartaiKabupaten = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/PerolehanSuara/SuperAdmin/selectedCaleg.vue')
const PerolehanSuaraPartaiKecamatan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/selectedCity.vue"
  );
const PerolehanSuaraPartaiKelurahan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/selectedDistrict.vue"
  );
const PerolehanSuaraPartaiTps = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/SuperAdmin/selectedVillage.vue"
  );

const PerolehanSuaraCaleg = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/Caleg/index.vue"
  );
const PerolehanSuaraCalegPartaiKecamatan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/Caleg/selectedCity.vue"
  );
const PerolehanSuaraCalegPartaiKelurahan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/Caleg/selectedDistrict.vue"
  );
const PerolehanCalegSuaraPartaiTps = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/PerolehanSuara/Caleg/selectedVillage.vue"
  );

const DptList = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Dpt/List.vue");

const PendukungList = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Pendukung/List.vue");
const PendukungForm = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Pendukung/Form.vue");
// const PendukungPartaiList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Pendukung/DptPendukungPartaiList.vue')

const SettingForm = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/Setting/Form.vue");

const CekDokumen = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/index.vue");
const CekDokumenListPartai = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/partyList.vue"
  );
const CekDokumenPartai = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/selectedParty.vue"
  );
// const CekDokumenPartaiKabupaten = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/CekDokumen/selectedCaleg.vue')
const CekDokumenPartaiKecamatan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/selectedCity.vue"
  );
const CekDokumenPartaiKelurahan = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/selectedDistrict.vue"
  );
const CekDokumenPartaiTps = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/pages/CekDokumen/selectedVillage.vue"
  );

const routes = [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login Account",
        component: Login,
      },
    ],
    meta: { requireGuest: true },
  },
  {
    path: "/logout",
    component: Logout,
    meta: { requireAuth: true },
  },
  {
    path: "/",
    component: GeneralScreenDashboard,
    children: [
      {
        path: "/",
        name: "Homepage",
        redirect: "dashboard",
        meta: { requireAuth: true },
      },
      {
        path: "dashboard",
        // alias: '/',
        name: "Dashboard",
        component: Dashboard,
        meta: { requireAuth: true },
      },
      {
        path: "/setting",
        name: "Empty Content Setting Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Pengaturan Akun",
            component: SettingForm,
            meta: { requireAuth: true },
          },
        ],
      },
      {
        path: "users",
        name: "Empty Content User Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Listing User",
            component: UserList,
            meta: { title: "Kelola Admin", neeedRole: ["super-admin"] },
          },
          {
            path: "create",
            name: "Tambah Data User",
            component: UserForm,
            meta: { title: "Tambah Admin", neeedRole: ["super-admin"] },
          },
          {
            path: ":id",
            name: "Detail Data User",
            component: UserDetail,
            meta: { title: "Detail Admin", neeedRole: ["super-admin"] },
          },
          {
            path: ":id/edit",
            name: "Ubah Data User",
            component: UserForm,
            meta: { title: "Ubah Admin", neeedRole: ["super-admin"] },
          },
        ],
      }, 
      {
        path: "koordinator",
        name: "Empty Content Koordinator Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Koordinator",
            component: Koordinator,
            meta: { title: "Koordinator", neeedRole: ["caleg"] },
          },
        ],
      },
      {
        path: "staff-calon",
        name: "Empty Content Staff Calon Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Listing Staff Calon",
            component: StaffCalonList,
            meta: { title: "Kelola Staff", neeedRole: ["caleg"] },
          },
          {
            path: "create",
            name: "Tambah Data Staff Calon",
            component: StaffCalonForm,
            meta: { title: "Tambah Staff", neeedRole: ["caleg"] },
          },
          {
            path: ":id",
            name: "Detail Data Staff Calon",
            component: StaffCalonDetail,
            meta: { title: "Detail Staff", neeedRole: ["caleg"] },
          },
          {
            path: ":id/edit",
            name: "Ubah Data Staff Calon",
            component: StaffCalonForm,
            meta: { title: "Ubah Staff", neeedRole: ["caleg"] },
          },
        ],
      },
      {
        path: "calon",
        name: "Empty Content Caleg Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Listing Caleg",
            component: CalegList,
            meta: { title: "Kelola Calon", neeedRole: ["super-admin"] },
          },
          {
            path: "create",
            name: "Tambah Data Caleg",
            component: CalegForm,
            meta: { title: "Tambah Calon", neeedRole: ["super-admin"] },
          },
          {
            path: ":id",
            name: "Detail Data Caleg",
            component: CalegDetail,
            meta: { title: "Detail Calon", neeedRole: ["super-admin"] },
          },
          {
            path: ":id/edit",
            name: "Ubah Data Caleg",
            component: CalegForm,
            meta: { title: "Ubah Calon", neeedRole: ["super-admin"] },
          },
        ],
      },
      {
        path: "perolehan-suara",
        name: "Empty Content Perolehan Suara Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Perolehan Suara",
            component: PerolehanSuaraPartai,
            meta: {
              title: "Perolehan Suara Calon",
              neeedRole: ["super-admin"],
            },
          },
          // {
          //   path: 'calon/:regionId',
          //   name: 'Pilih partai Perolehan Suara',
          //   component: PerolehanSuaraListPartai,
          //   meta: { title: 'Perolehan Suara Calon', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: 'calon/:regionId/:partyid',
          //   name: 'Pilih Daerah Perolehan Suara',
          //   component: PerolehanSuaraPartai,
          //   meta: { title: 'Perolehan Suara Calon', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId',
          //   name: 'Perolehan Suara Partai Kabupaten',
          //   component: PerolehanSuaraPartaiKabupaten,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          {
            // path: 'calon/:regionId/:partyid/caleg/:calegId',
            path: "calon/:calegId",
            name: "Perolehan Suara Partai Kecamatan",
            component: PerolehanSuaraPartaiKecamatan,
            meta: {
              title: "Perolehan Suara Caleg",
              neeedRole: ["super-admin"],
            },
          },
          {
            // path: ':partyid/:calegId/:cityId/:districtId',
            path: "calon/:calegId/:districtId",
            name: "Perolehan Suara Partai Kelurahan",
            component: PerolehanSuaraPartaiKelurahan,
            meta: { title: "Perolehan Suara", neeedRole: ["super-admin"] },
          },
          {
            // path: ':partyid/:calegId/:cityId/:districtId/:villageId',
            path: "calon/:calegId/:districtId/:villageId",
            name: "Perolehan Suara Partai TPS",
            component: PerolehanSuaraPartaiTps,
            meta: { title: "Perolehan Suara", neeedRole: ["super-admin"] },
          },
        ],
      },
      {
        path: "dpt",
        name: "Empty Content DPT Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "DPT List",
            component: DptList,
            meta: { title: "DPT", neeedRole: ["super-admin"] },
          },
          // {
          //   path: ':partyid',
          //   name: 'Perolehan Suara Partai',
          //   component: PerolehanSuaraPartai,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId',
          //   name: 'Perolehan Suara Partai Kabupaten',
          //   component: PerolehanSuaraPartaiKabupaten,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId',
          //   name: 'Perolehan Suara Partai Kecamatan',
          //   component: PerolehanSuaraPartaiKecamatan,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId/:districtId',
          //   name: 'Perolehan Suara Partai Kelurahan',
          //   component: PerolehanSuaraPartaiKelurahan,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId/:districtId/:villageId',
          //   name: 'Perolehan Suara Partai TPS',
          //   component: PerolehanSuaraPartaiTps,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
        ],
      },
      {
        path: "pendukung",
        name: "Empty Content Pendukung Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Pendukung List",
            component: PendukungList,
            meta: {
              title: "Pendukung",
              neeedRole: ["super-admin", "caleg", "staff-calon"],
            },
          },
          {
            path: "create",
            name: "Tambah Data Pendukung",
            component: PendukungForm,
            meta: {
              title: "Tambah Pendukung Non-DPT",
              neeedRole: ["super-admin", "caleg", "staff-calon"],
            },
          },

          {
            path: ":id/edit",
            name: "Ubah Data Pendukung",
            component: PendukungForm,
            meta: {
              title: "Ubah Pendukung Non-DPT",
              neeedRole: ["super-admin", "caleg", "staff-calon"],
            },
          },
          // {
          //   path: 'partai/:partyId',
          //   name: 'Pendukung Partai',
          //   component: PendukungPartaiList,
          //   meta: { title: 'Pendukung Partai', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid',
          //   name: 'Perolehan Suara Partai',
          //   component: PerolehanSuaraPartai,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId',
          //   name: 'Perolehan Suara Partai Kabupaten',
          //   component: PerolehanSuaraPartaiKabupaten,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId',
          //   name: 'Perolehan Suara Partai Kecamatan',
          //   component: PerolehanSuaraPartaiKecamatan,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId/:districtId',
          //   name: 'Perolehan Suara Partai Kelurahan',
          //   component: PerolehanSuaraPartaiKelurahan,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
          // {
          //   path: ':partyid/:calegId/:cityId/:districtId/:villageId',
          //   name: 'Perolehan Suara Partai TPS',
          //   component: PerolehanSuaraPartaiTps,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['super-admin'] }
          // },
        ],
      },
      {
        path: "perolehan-suara-calon",
        name: "Empty Content Perolehan Suara Caleg Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Perolehan Suara Caleg",
            component: PerolehanSuaraCaleg,
            meta: { title: "Perolehan Suara", neeedRole: ["caleg"] },
          },
          // {
          //   path: ':cityId',
          //   name: 'Perolehan Suara Partai Caleg Kecamatan',
          //   component: PerolehanSuaraCalegPartaiKecamatan,
          //   meta: { title: 'Perolehan Suara', neeedRole: ['caleg'] }
          // },
          {
            // path: ':cityId/:districtId',
            path: ":districtId",
            name: "Perolehan Suara Partai Caleg Kelurahan",
            component: PerolehanSuaraCalegPartaiKelurahan,
            meta: { title: "Perolehan Suara", neeedRole: ["caleg"] },
          },
          {
            // path: ':cityId/:districtId/:villageId',
            path: ":districtId/:villageId",
            name: "Perolehan Suara Partai Caleg TPS",
            component: PerolehanCalegSuaraPartaiTps,
            meta: { title: "Perolehan Suara", neeedRole: ["caleg"] },
          },
        ],
      },
      {
        path: "cek-dokumen",
        name: "Empty Content Cek Dokumen Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Cek Dokumen",
            component: CekDokumen,
            meta: { title: "Cek Dokumen", neeedRole: ["super-admin"] },
          },
          // {
          //   path: 'calon/:regionId',
          //   name: 'Pilih partai Cek Dokumen',
          //   component: CekDokumenListPartai,
          //   meta: { title: 'Cek Dokumen', neeedRole: ['super-admin'] }
          // },
          {
            path: "calon/:regionId/",
            name: "Pilih Daerah Cek Dokumen",
            component: CekDokumenPartai,
            meta: { title: "Cek Dokumen", neeedRole: ["super-admin"] },
          },
          {
            path: "calon/:regionId/:calegId",
            name: "Cek Dokumen Partai Kecamatan",
            component: CekDokumenPartaiKecamatan,
            meta: { title: "Cek Dokumen", neeedRole: ["super-admin"] },
          },
          {
            path: "calon/:regionId/:calegId/:districtId",
            name: "Cek Dokumen Partai Kelurahan",
            component: CekDokumenPartaiKelurahan,
            meta: { title: "Cek Dokumen", neeedRole: ["super-admin"] },
          },
          {
            path: "calon/:regionId/:calegId/:districtId/:villageId",
            name: "Cek Dokumen Partai TPS",
            component: CekDokumenPartaiTps,
            meta: { title: "Cek Dokumen", neeedRole: ["super-admin"] },
          },
        ],
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: "/403",
    component: Forbidden,
  },
  {
    path: "*",
    component: NotFound,
  },
];

Vue.use(VueRouter);
const router = new VueRouter({
  routes, // short for router: router
  mode: "history",
  linkActiveClass: "active",
});

if (store.getters["account/isLoggedIn"]) {
  store.dispatch("account/saveTokens", {
    tokens: store.getters["account/tokens"],
    accountData: null,
    isLocalLogin: true,
  });
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.neeedRole)) {
    if (!store.getters["account/isLoggedIn"]) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    }
    if (to.meta.neeedRole.includes(store.getters["account/accountData"].role)) {
      next();
    } else {
      next({
        path: "/403",
      });
    }
  } else if (to.matched.some((record) => record.meta.requireAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["account/isLoggedIn"]) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else if (to.matched.some((record) => record.meta.needPermission)) {
      const needPermission =
        typeof to.meta.needPermission === "function"
          ? to.meta.needPermission(to)
          : to.meta.needPermission;
      if (
        typeof to.meta.needPermission !== "undefined" &&
        store.getters["account/needPermission"](needPermission)
      ) {
        next();
      } else {
        next({
          path: "/403",
        });
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireGuest)) {
    if (store.getters["account/isLoggedIn"]) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
  window.scrollTo(0, 0);
});

export default router;
